*, :after, :before {
    box-sizing: border-box;
}

html {
	font-size: 16px;
	scroll-behavior: smooth;
	scrollbar-width: thin;
}

@media only screen and (min-width: 1366px) {
	html {
		font-size: 18px;
	}
}

@media only screen and (min-width: 1920px) {
	html {
		font-size: 20px;
	}
}
	
@media only screen and (min-width: 2560px) {
	html {
		font-size: 24px;
	}
}

body::-webkit-scrollbar {
    width: 3px;
}



body, input, select, textarea {
	font-family: 'Helvetica Neue', 'Arimo', 'Noto Sans TC', sans-serif;
	margin: 0;
	background-color: #F3F3F3;
}

a {
    text-decoration: none;
    font-weight: 500;
    color: #888;
}

strong, b {
	font-weight: 600;
}

em, i {
	font-style: italic;
}

p {
	margin: 0 0 2rem 0;
}

h1, h2, h3, h4, h5, h6 {
	font-weight: 600;
	line-height: 1.5;
	margin: 0 0 1rem 0;
	text-transform: uppercase;
	letter-spacing: 0.2rem;
}

h1 {
	font-size: 2.25rem;
	line-height: 1.3;
	letter-spacing: 0.5rem;
}

h2 {
	font-size: 1.5rem;
	line-height: 1.4;
	letter-spacing: 0.5rem;
}

h3 {
	font-size: 1rem;
}

h4 {
	font-size: 0.8rem;
}

h5 {
	font-size: 0.7rem;
}

h6 {
	font-size: 0.6rem;
}
